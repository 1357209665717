class ParishMap {
    constructor(container, options = {}) {
        this.container = $(container);        
        this.options = {
            mapType: "osm",
            parishes: [],
            
            enableAddressSearch: true,
            addressSearchPlaceholder: "Geben Sie Ihre Adresse ein, um Ihren Pfarrbezirk zu finden",

            height: "auto",
            
            showBuildingMarkers: true,
            autoHideBuildings: true,
            showBuildingPolygons: false,
            buildingBorderColor: "#000000",            

            showBorder: true,
            fillBorders: false,
            showNames: false,
            
            allowFullscreen: true,
            showControls: false
        };
        this.options = Object.assign(this.options, options);
        if (!(this.options.mapType in ParishMap.mapConfigurations)) {
            console.error("Invalid map Type");
            return;
        }

        this.isFullScreen = false;
        
        this.parishes = null;

        this.lastSearchQuery = "";
        this.lastSearchResults = null;
        this.searchBounds = null;
        this.searchResultsContainer = null;
        this.searchResultMarkers = [];
        this.searchResultPopovers = [];
        
        this.mapDiv = null;
        this.controlDiv = null;
        this.loadingDiv = null;

        // Leaflet layers and maps
        this.map = null;
        this.tileLayer = null;
        this.elements = {};
        this.defaultPopup = null;

        this.tileConfig = ParishMap.mapConfigurations[this.options.mapType];
        this.init();
        $(window).on("resize", this.onResize.bind(this));
    }

    async init() {
        this.container.addClass("parish-map");
        this.container.html(`
            <div class="map-controls"></div>
            <div class="map"></div>
            <div class="loading-overlay">                
                <div class="spinner-border" role="status">
                    <span class="visually-hidden">Wird geladen...</span>
                </div>
                <span>Karte wird geladen</span>
            </div>
        `);
        this.mapDiv = this.container.find(".map");
        this.loadingDiv = this.container.find(".loading-overlay");
        this.controlDiv = this.container.find(".map-controls");

        this.mapDiv.hide();
        this.controlDiv.hide();
        
        await this.getParishes();
        await this.getBuildings();
        this.resizeMap();   
        this.loadingDiv.hide();
        this.mapDiv.show();
        await this.initMap();
        await this.draw();     

        if (this.options.showControls) {
            this.setupControls();
            this.controlDiv.show();
        }
    }

    setupControls() {
        if (this.options.enableAddressSearch) {
            this.controlDiv.append(`
                <div class="address-search">
                    <div class="input-group">
                        <input type="text" class="form-control address-search-input" placeholder="${this.options.addressSearchPlaceholder}" aria-label="Suche" aria-describedby="basic-addon2">
                        <button class="address-search-button btn btn-outline-secondary" type="button">
                            Suchen
                        </button>
                        <button class="address-search-clear-button btn btn-outline-secondary" type="button">
                            <i class="fa-solid fa-xmark"></i>
                        </button>
                    </div>
                    <div class="search-results">
                        <div class="results"></div>
                    </div>
                </div>
            `);
            this.searchResultsContainer = this.controlDiv.find(".search-results");
            this.searchResultsContainer.hide();

            let input = this.controlDiv.find(".address-search-input");
            let button = this.controlDiv.find(".address-search-button");
            let clearButton = this.controlDiv.find(".address-search-clear-button");

            let queryErrorPopover = new bootstrap.Popover(input[0], {
                content: "Bitte geben Sie eine längere Adresse ein",
                trigger: "manual",
                placement: "bottom"
            });

            input.on("keydown", function(e) {
                if (e.key === "Enter") {
                    this.triggerSearch(input, button, queryErrorPopover);
                } else {
                    queryErrorPopover.hide();
                }
            }.bind(this));

            button.on("click", function() {
                this.triggerSearch(input, button, queryErrorPopover);
            }.bind(this));

            clearButton.on("click", function() {
                this.clearSearch(input);
            }.bind(this));
        }
    }

    clearSearch(input) {
        input.val("");
        for (let marker of this.searchResultMarkers) {
            marker.remove();
        }
        this.searchResultMarkers = [];
        this.searchResultPopovers = [];
        this.fitBounds();
        if (this.searchResultsContainer.is(":visible")) {
            this.searchResultsContainer.slideUp();
        }
    }

    async triggerSearch(input, button, popover) {
        let address = input.val();
        if (address.length < 8) {                    
            popover.show();
            return;
        }
        if (address === this.lastSearchQuery) {
            this.showSearchResults(this.lastSearchResults);
            return;
        }
        popover.hide();
        input.prop("disabled", true);
        button.prop("disabled", true);

        this.lastSearchQuery = address;
        this.lastSearchResults = await this.searchForParish(address);
        this.showSearchResults(this.lastSearchResults);
        
        input.prop("disabled", false);
        button.prop("disabled", false);
    }

    async searchForParish(address) {
        if (this.searchBounds === null) {
            this.searchBounds = false;
            let points = [];
            for (let parish of this.parishes) {
                points = points.concat(this.toLatLngArray(parish.polygonPoints));
            }
            
            if (points.length > 1) {
                this.searchBounds = L.latLngBounds(points).pad(0.5);
            }
        }
        let boundingBox = null;
        if (this.searchBounds !== false) {
            boundingBox = [
                this.searchBounds.getWest().toFixed(8), 
                this.searchBounds.getNorth().toFixed(8),
                this.searchBounds.getEast().toFixed(8),
                this.searchBounds.getSouth().toFixed(8)
            ];
        }
        return await OpenStreetMap.freeSearch(address, boundingBox);
    }

    async showSearchResults(results) {
        let contacts = await this.getContacts();
        let resultDiv = this.searchResultsContainer.find(".results");
        
        for (let marker of this.searchResultMarkers) {
            marker.remove();
        }
        this.searchResultMarkers = [];   
        
        resultDiv.html("");

        let showResultDiv = false;

        if (results.length === 0) {
            // No results
            let contactInfo = "";
            // let contactInfo = this.formatContacts(contacts, "<p>Wenden Sie sich gerne an eine der folgenden Ansprechpersonen:</p>");
            
            resultDiv.append(`
                <div class="search-result no-result">
                    <b>Ihre Adresse konnte nicht im Einzugsgebiet unserer Gemeinde gefunden werden.</b>
                    ${contactInfo}
                </div>
            `);
            showResultDiv = true;
            this.fitBounds();
        } else if (results.length > 0) {
            // One or multiple results
            // Create markers on the map        
            
            let bounds = null;

            let insideCount = 0;
            let outsideCount = 0;
            let matchedParishesIds = new Set();
            let matchedParishes = {};
            let sortedResults = {};

            for (let result of results) {
                let markerPoint = this.toLatLng(result.position);
                if (bounds === null) {
                    bounds = markerPoint.toBounds(2);
                } else {
                    bounds.extend(markerPoint);
                }

                let marker = this.getColoredMarker(markerPoint, "#FF8800");
                this.searchResultMarkers.push(marker);
                marker.addTo(this.map);
                
                let parish = this.getParishForPosition(markerPoint);
                result.parish = parish;
                if (parish === null) {                    
                    // marker.bindPopup(this.getParishPopupHtml(null, contacts, null, true));
                    marker.bindPopup(this.getParishPopupHtml(null, [], result.fullName, true));
                    outsideCount++;
                } else {       
                    if (!(parish.id in sortedResults)) {
                        sortedResults[parish.id] = [];
                    }
                    sortedResults[parish.id].push(result);
                    matchedParishesIds.add(parish.id);
                    matchedParishes[parish.id] = parish;

                    let address = null;
                    if (results.length > 1) {
                        address = result.fullName;
                    }
                    insideCount++;
                    marker.bindPopup(this.getParishPopupHtml(parish, [], address, true));
                }
            }


            if (results.length === 1) {
                // Single results
                showResultDiv = false;
                this.searchResultMarkers[0].openPopup();
            } else {
                // Multiple results
                if (insideCount === 0) {
                    resultDiv.append(`
                        <div class="search-result multiple-results">
                            <b>Alle Ergebnisse liegen außerhalb des Einzugsgebiets unserer Gemeinde.</b>
                        </div>
                    `);
                } else {
                    if (outsideCount > 0) {
                        resultDiv.append(`
                            <div class="search-result multiple-results">
                                <b>Einige Ergebnisse liegen außerhalb des Einzugsgebiets unserer Gemeinde</b>
                            </div>                            
                        `); 
                    } else if (matchedParishesIds.size === 1) {
                        let parishId = Array.from(matchedParishesIds.values())[0];                        
                        console.log(matchedParishesIds);
                        console.log(parishId);
                        console.log(matchedParishes);
                        let parish = matchedParishes[parishId];
                        console.log(parish);
                        let contactInfo = this.formatContacts(parish.contacts, `<p>Ansprechpersonen:</p>`);
                        resultDiv.append(`
                            <div class="search-result multiple-results">
                                <b>Alle gefundenen Ergebnisse gehören zum Pfarrbezirk "${parish.name}"</b>
                                ${contactInfo}
                            </div>                            
                        `);   
                    } else {
                        resultDiv.append(`
                            <div class="search-result multiple-results">
                                <b>Wir haben mehrere Ergebnisse gefunden, welche in unterschiedlichen Pfarrbezirken liegen.</b>
                            </div>                            
                        `); 
                    
                        for (let parishId in sortedResults) {
                            let parishResults = sortedResults[parishId];       
                            let parish = parishResults[0].parish;                     
                            let addresses = [];
                            for (let result of parishResults) {
                                addresses.push(`<p><i>${result.fullName}</i></p>`);
                            }
                            let contactInfo = this.formatContacts(parish.contacts, `<p>Ansprechpersonen:</p>`);
                            resultDiv.append(`
                                <div class="search-result" data-parish="${parish.id}">
                                    <b>${parish.name}</b>
                                    ${addresses.join("")}
                                    ${contactInfo}
                                </div>                            
                            `); 
                        }
                    }                    
                }

                showResultDiv = true;               
            }
            this.map.fitBounds(bounds.pad(0.1), {maxZoom: 14});
        }
        if (!this.searchResultsContainer.is(":visible") && showResultDiv) {
            this.searchResultsContainer.slideDown();
        } else if (this.searchResultsContainer.is(":visible") && !showResultDiv) {
            this.searchResultsContainer.slideUp();
        }
    }

    formatContacts(contacts, prefixWithContacts = null, emptyPrefix = null) {
        let lines = [];        
        if (contacts.length > 0) {
            if (prefixWithContacts !== null) {
                lines.push(prefixWithContacts);
            }

            let contactsHtml = "";
            for (let contact of contacts) {
                contactsHtml += `
                    <div class="contact">
                        ${this.formatContact(contact)}
                    </div>
                `;
            }
            contactsHtml = `<div class="contacts">${contactsHtml}</div>`;
            lines.push(contactsHtml);
        } else {
            if (emptyPrefix !== null) {
                lines.push(emptyPrefix);
            }
        }
        return lines.join("");
    }

    getParishForPosition(position) {
        for (let parish of this.parishes) {
            let points = this.toLatLngArray(parish.polygonPoints);
            if (this.isPositionInsidePolygon(position, points)) {
                return parish;
            }
        }
        return null;
    }

    isPositionInsidePolygon(position, polygonPoints) {            
        let x = position.lat;
        let y = position.lng;
    
        let inside = false;
        for (let i = 0, j = polygonPoints.length - 1; i < polygonPoints.length; j = i++) {
            let xi = polygonPoints[i].lat, yi = polygonPoints[i].lng;
            let xj = polygonPoints[j].lat, yj = polygonPoints[j].lng;
    
            let intersect = ((yi > y) != (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
            if (intersect) {
                inside = !inside;
            }
        }    
        return inside;
    };

    getColoredMarker(position, color) {
        const markerHtmlStyles = `
            background-color: ${color};
            width: 3rem;
            height: 3rem;
            display: block;
            left: -1.5rem;
            top: -1.5rem;
            position: relative;
            border-radius: 3rem 3rem 0;
            transform: rotate(45deg);
            border: 1px solid #FFFFFF;
            scale: 0.6;
        `;

        let icon = L.divIcon({
            className: "my-custom-pin",
            iconAnchor: [0, 24],
            labelAnchor: [-6, 0],
            popupAnchor: [0, -36],
            html: `<span style="${markerHtmlStyles}" />`
        });

        return L.marker(position, {
            icon: icon
        });
    }

    formatContact(contact) {
        let attributes = contact.attributes.attribute;
        let contactParts = [`
            <b>${attributes.name.value}</b>
        `];
        if ("description" in attributes && attributes.description.value !== "") {
            contactParts.push(`<i>${attributes.description.value}</i>`);
        }
        if ("mail" in attributes && attributes.mail.value !== "") {
            contactParts.push(`<a href="mailto:${attributes.mail.value}">${attributes.mail.value}</a>`);
        }
        if ("phone" in attributes && attributes.phone.value !== "") {
            let phone = attributes.phone.value;
            let callablePhone = phone.replace(" ", "").replace("/", "").replace("-", "");
            contactParts.push(`<a href="tel:${callablePhone}">${phone}</a>`);
        }
        return contactParts.join("<br />");
    }

    async getContacts(parishes = null) {
        if (parishes === null) {
            parishes = await this.getParishes();
        }
        let contacts = {};
        for (let parish of parishes) {
            for (let contact of parish.contacts) {
                contacts[contact.id] = contact;
            }
        }
        let contactList = [];
        for (let contactId in contacts) {
            contactList.push(contacts[contactId]);
        }
        return contactList;
    }

    onResize() {
        this.resizeMap();
        this.map.invalidateSize();
    }

    resizeMap() {
        // let height = $(window).height() - (this.container.offset().top - $(window).scrollTop());
        // height *= 0.9;
        let width = this.container.width();
        let height = 0;

        if (this.isFullScreen) {

        } else {
            switch (this.options.height) {
                case "auto":
                    if (width < 500) {
                        // Mobile
                        height = 500;
                    } else {
                        height = $(window).height() * 0.6;
                    }
                    break;
                case "aspect-4-3":
                    height = width * (3 / 4);
                    break;
                default:
                    height = 200;
                    if (this.options.height.includes("%")) {
                        let percentage = parseFloat(this.options.height.replace("%", ""));
                        height = $(window).height() * (percentage / 100);
                    } else if (this.options.height.includes("px")) {
                        height = Math.round(parseFloat(this.options.height.replace("px", "")));
                    }
            }
        }
        this.mapDiv.css("height", `${height}px`);
        this.mapDiv.css("width", `${width}px`);
    }

    async initMap() {
        let leafletOptions = ParishMap.leafletOptions;
        this.map = L.map(this.mapDiv[0], leafletOptions);
        this.map.attributionControl.setPrefix(`<a href="https://leaflet.com" target="_blank">Leaflet</a>`);
        await this.fitBounds();
        L.tileLayer(this.tileConfig["url"], this.tileConfig["opts"]).addTo(this.map);
    }

    async fitBounds() {
        let bounds = await this.getParishBounds();
        if (bounds !== null) {
            this.map.fitBounds(bounds.pad(0.1));
        }
    }

    toLatLngArray(points) {
        let convertedPoints = [];
        for (let point of points) {
            convertedPoints.push(this.toLatLng(point));
        }
        return convertedPoints;
    }

    toLatLng(point) {
        if (Array.isArray(point)) {
            return L.latLng(parseFloat(point[1]), parseFloat(point[0]));
        }
        if ("lng" in point) {
            return L.latLng(parseFloat(point.lat), parseFloat(point.lng));
        } else if ("lon" in point) {
            return L.latLng(parseFloat(point.lat), parseFloat(point.lon));
        }
        return null;
    }

    async draw() {
        // Clear existing elements
        for (let elementId in this.elements) {
            this.elements[elementId].remove();
        }
        this.elements = {};

        // Parish borders
        let parishes = await this.getParishes();
        let contacts = await this.getContacts();
        let filteredParishes = [];
        let includeAllParishes = this.options.parishes.length === 0;
        for (let parish of parishes) {
            if (includeAllParishes || parish.id in this.options.parishes) {
                filteredParishes.push(parish);
            }
        }
        if (this.options.showBorder || this.options.fillBorders) {
            for (let parish of filteredParishes) {
                let points = this.toLatLngArray(parish.polygonPoints);

                let color = parish.color !== "" ? parish.color : "#000000";
                if (points.length > 1) {
                    let polyOptions = {
                        "color": color,
                        "fillColor": color,
                        "stroke": this.options.showBorder,
                        "fill": this.options.fillBorders
                    };          
                    let polygon = L.polygon(points, polyOptions);
                    polygon.addTo(this.map);
                    this.elements[`parish-polygon-${parish.id}`] = polygon;
                    // polygon.bindPopup(this.getParishPopupHtml(parish));
                }
            }
        }

        // Parish Popups
        this.defaultPopup = L.popup({closeOnClick: true});
        this.map.on("dblclick", function(e) {           
            this.defaultPopup.setLatLng(e.latlng);
            this.defaultPopup.setContent(this.getParishPopupHtml(this.getParishForPosition(e.latlng)));
            // this.defaultPopup.setContent(this.getParishPopupHtml(this.getParishForPosition(e.latlng), contacts));
            this.defaultPopup.openOn(this.map);           
        }.bind(this));

        // Buildings
        if (this.options.showBuildingMarkers || this.options.showBuildingPolygons) {
            let buildings = await this.getBuildings();
            for (let buildingId in buildings) {
                let building = buildings[buildingId];
                let osmData = building.osmData;
                if (typeof osmData !== "object" || Array.isArray(osmData) || osmData === null) {
                    console.error(`No OSM Data for ${building.id} // ${building.name}`);
                    continue;
                }

                if (this.options.autoHideBuildings) {
                    // Check if building is associated to visible parish
                    let district = await CategorySemantics.getAssociatedDistrict(building);
                    if (district === null) {
                        continue;
                    }
                    let found = false;
                    for (let parish of filteredParishes) {
                        for (let parishDistrict of parish.districts) {
                            if (parishDistrict.id === district.id) {
                                found = true;
                                break;
                            }
                        }
                        if (found) {
                            break;
                        }
                    }
                    if (!found) {
                        continue;
                    }
                }

                // Draw Polygon
                if (this.options.showBuildingPolygons) {
                    let polygonPoints = this.toLatLngArray(osmData.polygonPoints);
                    let polyOptions = {
                        "color": this.options.buildingBorderColor,
                        "fillColor": "#ff0000",
                        "stroke": true,
                        "fill": false
                    }; 
                    let polygon = L.polygon(polygonPoints, polyOptions);
                    polygon.addTo(this.map);
                    this.elements[`building-polygon-${building.id}`] = polygon;
                    if (!this.options.showBuildingMarkers) {
                        polygon.bindPopup(this.getBuildingPopupHtml(building));
                    }
                }

                // Put marker on map
                if (this.options.showBuildingMarkers) {
                    let markerPoint = this.toLatLng(osmData.position);
                    let marker = this.getColoredMarker(markerPoint, "#152C55");
                    marker.addTo(this.map);
                    this.elements[`building-marker-${building.id}`] = marker;
                    marker.bindPopup(this.getBuildingPopupHtml(building));
                }
                                
            }
        }
    }

    getBuildingPopupHtml(building) {
        let parts = [
            `<b>${building.name}</b>`
        ].concat(building.addressRows);
        return parts.join("<br />");
    }

    getParishPopupHtml(parish, fallbackContacts = [], address = null, personalized = false) {
        let contacts = fallbackContacts;
        let name = "Keinem Pfarrbezirk zugeordnet";
        if (parish !== null) {
            contacts = parish.contacts;
            name = `${parish.name}`;
            if (personalized) {
                name = `Ihr Pfarrbezirk: ${name}`;
            }
        }

        let persons = "Ansprechperson";
        if (contacts.length > 1) {
            persons = "Ansprechpersonen";
        }
        let contactsHeader = `<p>${persons} für diesen Bezirk:</p>`;
        if (personalized) {            
            contactsHeader = `<p>${persons} für Ihren Bezirk:</p>`;
        }
        if (parish === null) {
            contactsHeader = `<p>Wenden Sie sich gerne an folgende ${persons}:</p>`;
        }

        let htmlLines = [`<b>${name}</b>`];
        let contactInfo = this.formatContacts(contacts, contactsHeader);
        if (contactInfo !== "") {
            htmlLines.push(contactInfo);
        }        
        if (address !== null) {
            htmlLines.push(`<p style="font-size: 0.8rem;">Diese Adresse:<br />${address}</p>`);
        }
        return htmlLines.join("<br />");
    }

    async getParishBounds() {
        let parishes = await this.getParishes();
        let points = [];
        for (let parish of parishes) {
            points = points.concat(parish.polygonPoints);
        }

        if (points.length > 1) {
            return L.latLngBounds(this.toLatLngArray(points));
        }
    }

    async getParishes() {
        if (this.parishes === null) {
            try {
                let result = await $.ajax({
                    "url": "api/parishes/get_parishes"
                });
                this.parishes = result.parishes;
            } catch (e) {
                console.error(e);                
            }
        }
        return this.parishes;
    }

    async getBuildings() {
        return await CategorySemantics.getBuildings();
    }

    static mapConfigurations = {
        "osm": {
            url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
            opts: {
                maxZoom: 19,
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>'
            }
        },
        "osm-wiki": {
            url: "https://maps.wikimedia.org/osm-intl/{z}/{x}/{y}.png",
            opts: {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>'
            }
        },
        "osm-humanitarian": {
            url: "http://a.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png",
            opts: {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>'
            }    
        },
        "osm-toner": {
            url: "http://tile.stamen.com/toner/{z}/{x}/{y}.png",
            opts: {
                attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, under <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a>. Data by <a href="http://openstreetmap.org">OpenStreetMap</a>, under <a href="http://www.openstreetmap.org/copyright">ODbL</a>.'
            }
        }
    }

    static leafletOptions = {
        zoomSnap: 0,
        doubleClickZoom: false
    }
}

$(function() {
    $(".parishes-map").each(function() {
        let options = $(this).data();
        let map = new ParishMap($(this), options);
    });
});